import "./src/styles/global.css";
import "./src/styles/library.css";
import "./src/styles/font/fonts.css";

/* External Scripts */
const addScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

const script2add = [];

export const onClientEntry = () => {
  window.onload = () => {
    script2add.forEach((script) => {
      addScript(script);
    });
  };
};
